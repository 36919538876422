import axios from 'axios'

const baseURL = process.env.REACT_APP_CNPJ_URL
const token = process.env.REACT_APP_CNPJ_TOKEN

export const api = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    'X-Strategy': 'token'
  }
})

export interface CnpjDataRaw {
  cnpj: string
  razaoSocial: string
  nomeFantasia?: string
  capitalSocial: string
  dataAbertura: string
  situacao: string
  dataSituacao: string
  situacaoEspecial?: string
  dataSituacaoEspecial?: string
  porte: {
    id?: string
    descricao?: string
  }
  natureza: {
    id?: string
    descricao?: string
  }
  simples: {
    optante?: boolean
    dataOpcao?: string
  }
  simei: {
    optante?: boolean
    dataOpcao?: string
  }
  endereco: {
    cep?: string
    logradouro?: string
    numero?: string
    complemento?: string
    bairro?: string
    municipio?: string
    ibgeMunicipio?: string
    uf?: string
    ibgeEstado?: string
  }
  contato: {
    telefones: string[]
    emails: string[]
  }
  cnaePrincipal: {
    id?: string
    descricao?: string
  }
  cnaeSecundarios: Array<{
    id?: string
    descricao?: string
  }>
  inscricoesEstaduais: Array<{
    numero?: string
    uf?: string
    ativo?: boolean
    dataStatus?: string
  }>
  socios: Array<{
    cpfCnpj?: string
    tipo?: string
    nome?: string
    dataEntrada?: string
    cpfRepresentante?: string
    nomeRepresentante?: string
    qualificacao?: {
      id?: string
      descricao?: string
    }
  }>
}

export interface CnpjData extends CnpjDataRaw {
  dataAberturaFormatted: string
}

export async function getCnpjData(cnpj: string): Promise<CnpjData | undefined> {
  const response = await api.get<CnpjDataRaw>(`/cnpj/${cnpj}`)

  if (response.status !== 200) return

  return {
    ...response.data,
    dataAberturaFormatted: response.data.dataAbertura
      ?.split('-')
      .reverse()
      .join('/')
  }
}
