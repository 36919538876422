import axios from 'axios'

const baseURL = process.env.REACT_APP_ADDRESS_URL
const token = process.env.REACT_APP_CNPJ_TOKEN

export const api = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    'X-Strategy': 'token'
  }
})

export interface CepData {
  cep: string
  logradouro?: string
  bairro?: string
  cidade: string
  ibgeCidade: string
  estado: string
  ibgeEstado: string
  siglaEstado: string
}

export interface State {
  ibge: string
  estado: string
  sigla: string
}

export async function getCep(cep: string): Promise<CepData> {
  const response = await api.get(`/cep/${cep}`)
  const data = response.status === 200 ? response.data : undefined

  return data
}

export async function getStates(): Promise<State[]> {
  const response = await api.get('/estado')
  const data = response.data

  return data
}
